import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import MainSectionZakaznickaZona from "../../components/zakaznicka-zona/mainSection.js";

import "../../styles/zakaznicka-zona.scss";
import ContactUs from "../../components/contactUs";
import ZonaSolutionBox from "../../components/zakaznicka-zona/zonaSolutionBox";
import ZonaFaqQuestion from "../../components/zakaznicka-zona/zonaFaqQuestion.js";
import SliderSectionZona from "../../components/zakaznicka-zona/sliderSection.js";
import References from "../../components/homepage/references.js";
import ZonaClankySlider from "../../components/zakaznicka-zona/zonaClankySlider";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";

import uvodnaKonzultaciaImg from "../../images/zakaznicka-zona/uvodna-konzultacia.jpg";
import navrhRieseniaImg from "../../images/zakaznicka-zona/navrh-riesenia.jpg";
import programovanieZonyImg from "../../images/zakaznicka-zona/programovanie-zony.jpg";
import podporaImg from "../../images/zakaznicka-zona/podpora.jpg";

import zadavanieIcon from "../../images/zakaznicka-zona/icons/zadavanie-obj.svg";
import generovanieIcon from "../../images/zakaznicka-zona/icons/generovanie-dokladov.svg";
import prezeranieIcon from "../../images/zakaznicka-zona/icons/prezeranie-stahovanie-dokumentov.svg";
import spristupnenieIcon from "../../images/zakaznicka-zona/icons/spristupnenie-udajov.svg";
import reportingIcon from "../../images/zakaznicka-zona/icons/reporting.svg";

import usporaIcon from "../../images/zakaznicka-zona/icons/uspora-casu.svg";
import znizenieIcon from "../../images/zakaznicka-zona/icons/znizenie-nakladov.svg";
import lojalitaIcon from "../../images/zakaznicka-zona/icons/vyssia-lojalita-zakaznikov.svg";
import fungovanieIcon from "../../images/zakaznicka-zona/icons/nepretrzite-fungovanie.svg";
import rizikoIcon from "../../images/zakaznicka-zona/icons/nizsie-riziko-chyb.svg";

import zonesColage from "../../images/zakaznicka-zona/okna-eng.jpg";

export const pageQuery = graphql`
  query {
    articles: wpCategory(slug: { eq: "zakaznicka-zona" }) {
      posts {
        nodes {
          title
          excerpt
          slug
          featuredImage {
            node {
              altText
              title
              gatsbyImage(width: 1920)
              sourceUrl
            }
          }
        }
      }
    }
    mysqlProjects(url: { eq: "plusim" }) {
      url
      real_url
      client
      hashtag
      project_description
      case_study_title
      case_study_rok
      case_study_sluzby
      case_study_vyzva
      case_study_proces
      case_study_riesenie
      ogDescription
    }
    files: allFile(
      filter: {
        relativeDirectory: { eq: "plusim" }
        sourceInstanceName: { eq: "images-projects" }
      }
    ) {
      nodes {
        name
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    projekty: allMysqlProjects(sort: { fields: case_study_rok, order: DESC }) {
      nodes {
        url
        client
        case_study_description
      }
    }
    logosWhite: allFile(
      filter: {
        name: { eq: "logo-white" }
        sourceInstanceName: { eq: "images-projects" }
      }
    ) {
      nodes {
        name
        relativeDirectory
        publicURL
      }
    }
  }
`;

const ZakaznickaZonaEn = (pageQuery) => {
  const images = {};
  pageQuery.data.files.nodes.map((node) => {
    let index = node.name;
    images[index] = node;
  });

  return (
    <Layout pageName="zakaznicka-zona-main">
      <SEO
        title="Customer Zone"
        description="We create bespoke customer zones that save you time and costs, streamline processes and help increase customer loyalty."
      />
      <MainSectionZakaznickaZona
        heading="Customer Zone"
        textHtml={
          <b>
            We create bespoke customer zones that save you time and costs,{" "}
            <br />
            streamline processes and help increase customer loyalty.
          </b>
        }
      />
      <section id="what-is-client-zone">
        <ZonaSolutionBox
          heading="What is a customer zone?"
          text="A customer zone, otherwise known as a client portal, B2B portal or customer portal, is an environment that is designed for your clients. It can be part of a website or function as a standalone application. Customers access it by default through their login credentials."
        />
      </section>

      {/*<SliderSectionZona />*/}
      <section id="client-zone-colage">
        <img
          className="img"
          src={zonesColage}
          alt="Customer Zones"
          loading="lazy"
        />
      </section>

      <section className="section-blog">
        <div className="banner-blog">
          <h2>The client portal and its options</h2>
          <p>
            You can have various functions programmed into the customized
            Customer Zone. It's up to you which ones you want to use. These are
            among the most requested ones.
          </p>
        </div>
      </section>
      <section id="case-study-info">
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={zadavanieIcon}
                alt="Entering new orders"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Entering new orders{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Through the Customer Zone, clients can quickly place new orders
              without having to re-enter their personal or company details.
              Copying older orders is a convenient function, as well as querying
              or returning goods.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={generovanieIcon}
                alt="Document generation"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Document generation{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              When a customer places an order via the Customer Zone, an invoice
              or other document can be automatically generated.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={prezeranieIcon}
                alt="Viewing and downloading documents"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Viewing and downloading documents{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Customers can have previous orders stored in their profile,
              including all documents. They can go back to them at any time and
              download them.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={spristupnenieIcon}
                alt="Access to data"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Access to data{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Through the client portal, you can make the data of your choice
              available to your customers – e.g. stock status, delivery time,
              current price and many other data.
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={reportingIcon}
                alt="Reporting"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Reporting{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              The Customer Zone can generate reports with data according to your
              needs at regular intervals and can automatically send them to your
              email.
            </p>
          </div>
        </div>
      </section>

      <section id="case-study-process" className="case-study-process-mac">
        <div className="comparison2">
          <GatsbyImage
            className="mockup-notebook notebook-image"
            image={getImage(images["web-new"])}
            alt="Mac"
          />
        </div>
      </section>

      <section className="section-blog">
        <div className="banner-blog">
          <h2>Key advantages of the client portal</h2>
          <p>
            Nowadays, the client portal is not only used by large companies, but
            also by medium and smaller companies. Why is it worthwhile for you,
            too? You will undoubtedly appreciate these benefits.
          </p>
        </div>
      </section>
      <section id="case-study-info">
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={usporaIcon}
                alt="Saving time"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Saving time{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              The Customer Zone automates many processes for you, saving you
              significant time. Your employees don't have to spend so much
              energy on phone calls and emails from customers or dealing with
              laborious paperwork and can instead focus on more important tasks.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={znizenieIcon}
                alt="Reduce costs"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Reduce costs{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Because the Customer Zone covers many of the day-to-day processes,
              your employees won't spend as much time issuing invoices and
              orders or answering frequent client queries. This will save you a
              significant amount on regular costs.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={lojalitaIcon}
                alt="Greater customer loyalty"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Greater customer loyalty{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              A client portal will distinguish you from your competitors and
              give your customers a very pleasant user experience. As a result,
              they will be happy to use your services again and purchase
              products on a long term basis.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={fungovanieIcon}
                alt="Business continuity"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Business continuity{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              The Customer Zone operates 24 hours a day, 7 days a week. Clients
              can make full use of it even when all your employees are at home
              or on holiday. So the business never sleeps and can generate
              revenue for you throughout the day.
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={rizikoIcon}
                alt="Lower risk of errors and data loss"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Lower risk of errors and data loss{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Thanks to the customer portal, you will no longer forget an order
              or lose important data. All the information is in one place, in a
              clear format.
            </p>
          </div>
        </div>
      </section>

      <section id="online-business-solutions">
        <h2>How is the Customer Zone created?</h2>
        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={uvodnaKonzultaciaImg}
            alt="Initial consultation"
            loading="lazy"
          />
          <div className="solution-description">
            <h3>1. Initial consultation</h3>
            <p>
              You tell us what problems you face in your business and what your
              future Customer Zone should solve. We will offer you options on
              what it could contain and what functionalities are good to
              incorporate. We can create a client zone for you in the form of a
              web, mobile or desktop application.
            </p>
            <br />
            <p className="bold">
              The first meeting is on us and lasts 1 – 2 hours
            </p>
          </div>
        </div>

        <div className="solution-box loaded">
          <div className="solution-description">
            <h3>2. Solution design</h3>
            <p>
              We will develop a solution proposal, which will include prices for
              individual services, calculation of the number of hours of work,
              delivery time and other important data. We will present to you
              what the individual functions and sections of the future client
              zone may look like.
              <br />
              <br />
              It will help us if we know your idea of the look and functionality
              of the client zone before we develop the solution design. For
              example, you can send us a screenshot or video from another client
              zone that you like. Based on your requirements and financial
              capabilities, we will recommend either a completely customized
              graphic or a design based on a pre-made template that is further
              customized.
            </p>
            <br />
            <p className="bold">Solution design takes us 2 – 3 weeks</p>
          </div>
          <img
            className="img solution-img"
            src={navrhRieseniaImg}
            alt="Solution design"
            loading="lazy"
          />
        </div>

        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={programovanieZonyImg}
            alt="Client zone programming"
            loading="lazy"
          />
          <div className="solution-description">
            <h3>3. Client zone programming</h3>
            <p>
              The programming of the client zone itself is divided into phases.
              During the creation process, we gather your feedback and make
              adjustments based on it. The design is also developed and adapted
              in different phases.
            </p>
            <br />
            <p className="bold">Implementation can take 2 – 6 months</p>
          </div>
        </div>

        <div className="solution-box loaded">
          <div className="solution-description">
            <h3>4. Support</h3>
            <p>
              The client zone is a so-called live project. It requires regular
              maintenance in order to work 100% in the long term. Once it is
              completed and handed over, we take care of the service and our
              support is available at any time. If you want to add new features
              to your client zone over time, we can program them for you.
            </p>
            <br />
            <p className="bold">Training and launch can take 2 – 3 days</p>
          </div>
          <img
            className="img solution-img"
            src={podporaImg}
            alt="Support"
            loading="lazy"
          />
        </div>
      </section>

      {/*<References />*/}

      {/*<ZonaClankySlider
        clanky={pageQuery.data.articles}
    />*/}

      <section id="zonaFaq">
        <h2>
          Frequently Asked Questions
          <StaticImage
            className="img"
            src="../../images/new-web/arrow.svg"
            alt="Šípka vpravo"
            placeholder="blurred"
          />
        </h2>

        <ZonaFaqQuestion
          question="Can a client zone send me notifications?"
          answer={
            <>
              Yes, we recommend this feature to any business that uses a client
              zone. What do notifications look like in practice? For example,
              every 24 hours (or every week or month) a summary of all orders
              can automatically arrive in your email inbox. Such reports are a
              great base for management and can save a lot of time.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Does the web client area also work on mobile?"
          answer={
            <>
              If your employees or clients will be using the web client zone in
              the field, we can program it to be responsive. That is, so that it
              can be fully accessible and used on mobile devices.
            </>
          }
        />

        <ZonaFaqQuestion
          question="How much money will the client zone save me?"
          answer={
            <>
              A Customer Zone can reduce costs for companies by up to 30%,
              mainly by simplifying many processes and saving time. As a result,
              you will see a return on your investment in just 2 years.
            </>
          }
        />

        <ZonaFaqQuestion
          question="What can your future Customer Zone look like?"
          answer={
            <>
              We'd be happy to talk to you about it. Book a free telephone
              consultation and find out what we can create for you.
            </>
          }
        />
      </section>
      <ContactUs
        text={
          <>
            Are you interested in a Customer zone? <br />
            Contact Us
          </>
        }
        button="Write to us"
        numberButtonSeparator={"or"}
        enText
      />
    </Layout>
  );
};

export default ZakaznickaZonaEn;
